var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"row page-title align-items-center"},[_c('div',{staticClass:"col-md-3 col-xl-6"},[_c('h4',{staticClass:"mb-1 mt-0"},[_vm._v("Content "+_vm._s(_vm.title))])]),_c('div',{staticClass:"col-md-3 col-xl-6"},[(
          _vm.catName !== 'VIDEO' && 
          _vm.catName !== 'VIDEO FIGHT ANALYSIS' &&
          _vm.title !== 'BASIC' &&
          _vm.title !== 'INTERVIEW' &&
          _vm.title !== 'TECHNIQUE AND TACTIC'
          )?_c('div',[_c('router-link',{attrs:{"to":`/newPremium/content/create?categoryID=${_vm.id}`}},[_c('button',{staticClass:"btn btn-primary rounded-pill width-lg float-right aligncenter"},[_c('feather',{attrs:{"type":"file-plus"}}),_vm._v("Create\n          ")],1)])],1):_vm._e(),(_vm.title === 'BASIC' || _vm.title === 'INTERVIEW' || _vm.title === 'TECHNIQUE AND TACTIC')?_c('div',[_c('router-link',{attrs:{"to":`/newPremium/content/create?categoryID=${_vm.id}&highlight=true`}},[_c('button',{staticClass:"btn btn-primary rounded-pill width-lg float-right aligncenter"},[_c('feather',{attrs:{"type":"file-plus"}}),_vm._v("Create\n          ")],1)])],1):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('Search',{attrs:{"types":_vm.searchTypes},on:{"onSearch":function($event){return _vm.onSearch($event)}}})],1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table mb-0"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("#")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Content")]),_c('th',{staticClass:"tf-2",attrs:{"scope":"col"}},[_vm._v("Title")]),_c('th',{staticClass:"tf-3",attrs:{"scope":"col"}},[_vm._v("Update At")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Status")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("HighLight")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Action")])])]),_c('tbody',_vm._l((_vm.data),function(content,index){return _c('tr',{key:index},[_c('td',{attrs:{"scope":"row"}},[_vm._v("\n                    "+_vm._s(_vm.page === 1
                        ? _vm.page - 1 + index + 1
                        : (_vm.page - 1) * 10 + (index + 1))+"\n                  ")]),_c('td',{staticStyle:{"max-width":"175px"},attrs:{"scope":"row"}},[(content.video_data)?_c('iframe',{attrs:{"src":`https://player.vimeo.com/video/${content.video_data.vimeoid}`,"width":"225","height":"125","frameborder":"0","allow":"  fullscreen","allowfullscreen":""}}):_c('img',{staticClass:"img-fluid",staticStyle:{"width":"225px","height":"163px"},attrs:{"src":`${_vm.baseImageUrl}/${content.img_url}`,"alt":""}})]),_c('td',{staticStyle:{"width":"200px","max-width":"250px"},attrs:{"scope":"row"}},[_vm._v("\n                    "+_vm._s(content.content_title)+"\n                  ")]),_c('td',{staticStyle:{"max-width":"150px"},attrs:{"scope":"row"}},[_vm._v(_vm._s(content.updatedAt.substring(0, 10)))]),_c('td',{staticStyle:{"max-width":"150px"},attrs:{"scope":"row"}},[(content.status)?_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v("Show")]):_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v("Hide")])],1),_c('td',{staticStyle:{"max-width":"150px"},attrs:{"scope":"row"}},[(content.feat)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("True")]):_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v("False")])],1),_c('td',{staticClass:"d-flex"},[(_vm.title === 'BASIC' || _vm.title === 'INTERVIEW' || _vm.title === 'TECHNIQUE AND TACTIC')?_c('div',[_c('button',{staticClass:"btn btn-info p-0 px-2 py-1 mr-2",on:{"click":function($event){return _vm.handleClickAddVideo(content)}}},[_vm._v("Video")])]):_vm._e(),_c('button',{staticClass:"btn btn-warning p-0 px-2 py-1 mr-2",on:{"click":function($event){return _vm.handleClickEditContent(content)}}},[_vm._v("Edit")]),(
                        _vm.title !== 'VIDEO' && _vm.title !== 'VIDEO FIGHT ANALYSIS'
                      )?_c('div',[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-center",modifiers:{"modal-center":true}}],staticClass:"btn btn-danger p-0 px-2 py-1",on:{"click":function($event){return _vm.handleClickDeleteContent(content._id)}}},[_vm._v("Delete")])],1):_vm._e()])])}),0)])]),_c('Pagination',{attrs:{"data":_vm.pagination,"page":_vm.page},on:{"onPage":function($event){return _vm.onPage($event)}}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }